/**
 * Exclude specific dates here.
 * These will be disabled in the datepicker
 * on the lead form.
 */

export default [
  new Date('September 1, 2024'),
  new Date('September 2, 2024'),
  new Date('September 3, 2024'),
  new Date('September 4, 2024'),
  new Date('September 5, 2024'),
  new Date('September 6, 2024'),
  new Date('September 7, 2024'),
  new Date('September 8, 2024'),
  new Date('September 9, 2024'),
  new Date('September 10, 2024'),
  new Date('September 11, 2024'),
  new Date('September 12, 2024'),
  new Date('September 13, 2024'),
  new Date('September 14, 2024'),
  new Date('September 15, 2024'),
  new Date('September 16, 2024'),
  new Date('September 17, 2024'),
  new Date('September 18, 2024'),
  new Date('September 19, 2024'),
  new Date('September 20, 2024'),
  new Date('September 21, 2024'),
  new Date('September 22, 2024'),
  new Date('September 23, 2024'),
  new Date('September 24, 2024'),
  new Date('September 25, 2024'),
  new Date('September 26, 2024'),
  new Date('September 27, 2024'),
  new Date('September 28, 2024'),
  new Date('September 29, 2024'),
  new Date('September 30, 2024'),
  new Date('October 1, 2024'),
  new Date('October 2, 2024'),
  new Date('October 3, 2024'),
  new Date('October 4, 2024'),
  new Date('October 5, 2024'),
  new Date('October 6, 2024'),
  new Date('October 7, 2024'),
  new Date('October 8, 2024'),
  new Date('October 9, 2024'),
  new Date('October 10, 2024'),
  new Date('October 11, 2024'),
  new Date('October 12, 2024'),
  new Date('October 13, 2024'),
  new Date('October 14, 2024'),
  new Date('October 15, 2024'),
  new Date('October 16, 2024'),
  new Date('October 17, 2024'),
  new Date('October 18, 2024'),
  new Date('October 19, 2024'),
  new Date('October 20, 2024'),
  new Date('October 21, 2024'),
  new Date('October 22, 2024'),
  new Date('October 23, 2024'),
  new Date('October 24, 2024'),
  new Date('October 25, 2024'),
  new Date('October 26, 2024'),
  new Date('October 27, 2024'),
  new Date('October 28, 2024'),
  new Date('October 29, 2024'),
  new Date('October 30, 2024'),
  new Date('October 31, 2024'),
  new Date('November 1, 2024'),
  new Date('November 2, 2024'),
  new Date('November 3, 2024'),
  new Date('November 4, 2024'),
  new Date('November 5, 2024'),
  new Date('November 6, 2024'),
  new Date('November 7, 2024'),
  new Date('November 8, 2024'),
  new Date('November 9, 2024'),
  new Date('November 10, 2024'),
  new Date('November 11, 2024'),
  new Date('November 12, 2024'),
  new Date('November 13, 2024'),
  new Date('November 14, 2024'),
  new Date('November 15, 2024'),
  new Date('November 16, 2024'),
  new Date('November 17, 2024'),
  new Date('November 18, 2024'),
  new Date('November 19, 2024'),
  new Date('November 20, 2024'),
  new Date('November 21, 2024'),
  new Date('November 22, 2024'),
  new Date('November 23, 2024'),
  new Date('November 24, 2024'),
  new Date('November 25, 2024'),
  new Date('November 26, 2024'),
  new Date('November 27, 2024'),
  new Date('November 28, 2024'),
  new Date('November 29, 2024'),
  new Date('November 30, 2024'),
  new Date('December 1, 2024'),
  new Date('December 2, 2024'),
  new Date('December 3, 2024'),
  new Date('December 4, 2024'),
  new Date('December 5, 2024'),
  new Date('December 6, 2024'),
  new Date('December 7, 2024'),
  new Date('December 8, 2024'),
  new Date('December 9, 2024'),
  new Date('December 10, 2024'),
  new Date('December 11, 2024'),
  new Date('December 12, 2024'),
  new Date('December 13, 2024'),
  new Date('December 14, 2024'),
  new Date('December 15, 2024'),
  new Date('December 16, 2024'),
  new Date('December 17, 2024'),
  new Date('December 18, 2024'),
  new Date('December 19, 2024'),
  new Date('December 20, 2024'),
  new Date('December 21, 2024'),
  new Date('December 22, 2024'),
  new Date('December 23, 2024'),
  new Date('December 24, 2024'),
  new Date('December 25, 2024'),
  new Date('December 26, 2024'),
  new Date('December 27, 2024'),
  new Date('December 28, 2024'),
  new Date('December 29, 2024'),
  new Date('December 30, 2024'),
  new Date('December 31, 2024')
];
