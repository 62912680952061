import {
  ACCEPTS,
  PAGE_TITLE,
  SLOW_BLINKS_EMAIL,
  VENMO_USERNAME
} from '../../constants';

export default () => (
  <footer>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <a href="/">
        <img
          id="footer-logo"
          src="/cat.gif"
          alt={PAGE_TITLE}
          width={200}
          height={112}
        />
      </a>
    </div>
    <a href={`mailto:${SLOW_BLINKS_EMAIL}`}>{SLOW_BLINKS_EMAIL}</a>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1rem',
        fontSize: '0.9em'
      }}
    >
      <span style={{ color: '#c273ff' }}>{ACCEPTS}&nbsp;</span>
      <a
        href={`https://account.venmo.com/u/${VENMO_USERNAME.replace('@', '')}`}
        target="_blank"
        rel="noreferrer"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          id="venmo"
          src="/venmo.svg"
          alt="Venmo"
          width={76.21}
          height={14}
        />
      </a>
    </div>
  </footer>
);
