export default ({ style = {} }) => (
  <div
    className="profile-photo"
    style={{
      width: style.width || '96px',
      height: style.height || '96px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: style.margin || '2rem auto 0'
    }}
  >
    <div
      style={{
        display: 'block',
        borderRadius: '100vw',
        overflow: 'hidden',
        width: style.width || '96px',
        height: style.height || '96px',

        ...style
      }}
    >
      <img
        src="/cat-profile.png"
        width={style.width || '96px'}
        height={style.height || '96px'}
        alt="Profile pic"
        style={{
          display: 'block',
          borderRadius: 'inherit'
        }}
      />
    </div>
  </div>
);
