import { useState, useEffect } from 'react';

import { Footer, Header, Print, Screen } from './components';

import {
  About,
  Bill,
  Calendar,
  Customers,
  FAQ,
  Hosting,
  InvoiceBuilder,
  Invoices,
  Login,
  Profile,
  Visits
} from './pages';

import {
  ABOUT,
  EXACTCHANGE_URL,
  FAQ_HEADING,
  HOSTING,
  VISITS
} from './constants';

import Outage from './components/Outage';

export default () => {
  const [pageName, slug, resourceId] = window.location.pathname
    .trim()
    .toLowerCase()
    .split('/')
    .filter(Boolean);

  const [online, setOnline] = useState(true);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(EXACTCHANGE_URL);

        if (!response?.ok) {
          setOnline(false);
        }
      } catch (error) {
        setOnline(false);
      }
    };

    fetchStatus();
  }, []);

  useEffect(() => {
    if (!pageName || pageName === '/') {
      window.location.href = '/visits';
    }
  }, [pageName]);

  if (!pageName) return <div />;

  const Routes = {
    undefined: <Visits />,
    hosting: <Hosting />,
    visits: <Visits />,
    about: <About />,
    faq: <FAQ />,
    calendar: <Calendar />,
    profile: <Profile slug={slug} />,
    login: <Login />,
    invoice: resourceId ? (
      <Bill slug={slug} id={resourceId} />
    ) : (
      <InvoiceBuilder slug={slug} />
    ),
    invoices: <Invoices />,
    customers: <Customers />
  };

  const component = Routes[pageName];

  return pageName === 'invoice' && resourceId ? (
    <Print>{component}</Print>
  ) : (
    <Screen title={pageName}>
      {online
      ? <>
        <Header />
        <ul role="navigation" id="nav">
          <li>
            <a href="/visits" disabled={pageName.includes('visits')}>
              {VISITS}
            </a>
          </li>
          <li>
            <a href="/about" disabled={pageName === 'about'}>
              {ABOUT}
            </a>
          </li>
          <li>
            <a href="/faq" disabled={pageName === 'faq'}>
              {FAQ_HEADING}
            </a>
          </li>
          <li>
            <a href="/hosting" disabled={pageName === 'hosting'}>
              {HOSTING}
            </a>
          </li>
        </ul>
        <section>{component}</section>
        <Footer />
      </> : <div style={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Outage />
      </div>}
    </Screen>
  );
};
