import { useEffect, useState } from 'react';

import useTokenAuth from '../useTokenAuth';

import postJSON from '../../utils/postJSON';

import { CALENDAR_URL } from '../../constants';

export default () => {
  const { email, token, deleteSession } = useTokenAuth();

  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEvents = async () => {
    const result = await postJSON(CALENDAR_URL, {
      token,
      username: email
    });

    if (result?.events?.length) {
      setEvents(
        result.events.map(
          ({
            id,
            title,
            start,
            end,
            desc,
            catName,
            ownerName,
            customerId
          }) => ({
            id,
            title,
            start: new Date(start),
            end: new Date(end),
            desc,
            catName,
            ownerName,
            customerId
          })
        )
      );

      setIsLoading(false);

      return;
    }

    deleteSession();
  };

  return {
    events,
    setEvents,
    isLoading,
    setIsLoading,
    fetchEvents
  };
};
