import { EventDateTime, SegmentedControl } from '..';
import { useEvents, useTokenAuth } from '../../hooks';

import postJSON from '../../utils/postJSON';

import { CLOSE, CUSTOMER, EVENT_URL } from '../../constants';

export default ({
  isPm = false,
  booking = {},
  setIsPm,
  setBooking,
  shouldSave,
  setShouldSave,
  setIsSidebarStart,
  customers
}) => {
  const { email, token } = useTokenAuth();
  const { fetchEvents } = useEvents();

  const onClickClose = async () => {
    if (shouldSave) {
      const { success } = await postJSON(EVENT_URL, {
        ...booking,

        token,
        username: email
      });

      if (success) {
        fetchEvents();
      }

      setShouldSave(false);
    }

    setBooking({});
  };

  const onChangeCustomer = ({ target: { value } }) => {
    const customer = customers.find(({ id }) => id === value);

    if (!customer) return;

    const {
      catName,
      ownerName,
      id: customerId
    } = customer;

    setBooking({
      ...booking,

      title: `${catName} (${ownerName})`,
      catName,
      ownerName,
      customerId
    });
  };

  return (
    <div className={booking.id ? 'sidebar-open' : 'sidebar'}>
      <div style={{ cursor: 'pointer' }}>
        <h3>{CUSTOMER}:</h3>
        <select
          name="customers"
          id="customers"
          style={{
            width: '100%',
            padding: '.5rem',
            appearance: 'none',
            background: 'white',
            color: 'black',
            fontSize: '1em',
            cursor: 'pointer',
            border: '2px solid #9418ff',
            borderRadius: '.5rem'
          }}
          onChange={onChangeCustomer}
        >
          {customers.map(({ id, catName, ownerName }) => (
            <option value={id} key={id}>
              {catName} ({ownerName})
            </option>
          ))}
        </select>
      </div>
      {booking.id && (
        <SegmentedControl
          startComponent={
            <EventDateTime
              startOrEnd="start"
              booking={booking}
              setBooking={setBooking}
              setShouldSave={setShouldSave}
              isPm={isPm}
              setIsPm={setIsPm}
            />
          }
          endComponent={
            <EventDateTime
              startOrEnd="end"
              booking={booking}
              setBooking={setBooking}
              setShouldSave={setShouldSave}
              isPm={isPm}
              setIsPm={setIsPm}
            />
          }
          booking={booking}
          setIsPm={setIsPm}
          setIsSidebarStart={setIsSidebarStart}
        />
      )}
      <button
        onClick={onClickClose}
        style={{
          position: 'absolute',
          bottom: 0,
          left: '1rem',
          right: '1rem',
          top: 'auto',
          width: 'calc(100% - 2rem)'
        }}
      >
        {shouldSave ? 'Save & ' : ''}
        {CLOSE}
      </button>
    </div>
  );
};
