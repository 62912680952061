import { useState } from 'react';

import { SidebarNav } from '../../components';
import { useCustomers } from '../../hooks';

import { ACTIONS, CAT, INVOICE, INVOICES, NAME } from '../../constants';

export default () => {
  const { customers, isLoading } = useCustomers();
  const [isSidebarNavOpen, setIsSidebarNavOpen] = useState(false);

  const onClickMenu = () => {
    setIsSidebarNavOpen(!isSidebarNavOpen);
  };

  return isLoading ? (
    <div />
  ) : (
    <section className="sidebar-section">
      <SidebarNav isOpen={isSidebarNavOpen} setIsOpen={setIsSidebarNavOpen} />
      <div className="customers-wrapper">
        <h2>
          <i onClick={onClickMenu}>=</i>
          {INVOICES}
        </h2>
        <ul className="table">
          <li>
            <span className="dashboard-heading">💳</span>
            <span className="dashboard-heading">{NAME}</span>
            <span className="dashboard-heading">{CAT}</span>
            <span />
            <strong className="dashboard-heading">{ACTIONS}</strong>
          </li>
          {customers &&
            customers.map(({ id, slug, ownerName, catName }) => (
              <li key={id}>
                <span>
                  <a href={`/invoice/${slug}`}>
                    <i>+</i>
                  </a>
                </span>
                <span>
                  <a href={`/profile/${slug}`}>{ownerName}</a>
                </span>
                <span>
                  <a href={`/profile/${slug}`}>{catName}</a>
                </span>
                <span />
                <a
                  className="button-link"
                  style={{ background: '#32be3f' }}
                  href={`/invoice/${slug}`}
                >
                  + {INVOICE}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};
