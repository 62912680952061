import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Switch from 'react-switch';

import NoVacancy from './NoVacancy';
import excludedDates from './excludedDates';

import capitalize from '../../utils/capitalize';
import postJSON from '../../utils/postJSON';
import toYesNo from '../../utils/toYesNo';

import {
  ADDITIONAL_INFO_LABEL,
  ADDRESS_LABEL,
  ADDRESS_LABEL_FULL,
  ADMINISTER_MEDICATION,
  BOOK_HOSTING_URL,
  BOOK_IT,
  CALENDAR_EMOJI,
  CAT_DESCRIPTION,
  CAT_NAME_LABEL_FULL,
  CHECK_IN_LABEL,
  CHECK_IN_TIME,
  CHECK_OUT_LABEL,
  CHECK_OUT_TIME,
  CLOCK_EMOJI,
  COMMENTS_LABEL,
  CONFIRM,
  DROPOFF_ADDRESS,
  EDIT,
  EMAIL_ADDRESS,
  EMAIL_LABEL,
  EMAIL_LABEL_FULL,
  EXTRAS_LABEL,
  FROM_LABEL,
  HOSTING,
  HOSTING_DISCLAIMER,
  HOSTING_PRICE,
  INVALID_LABEL,
  KITTY_NEEDS_A_RIDE,
  KITTY_NEEDS_A_RIDE_FULL,
  KITTY_QUIRKS_LABEL,
  NAME,
  NAME_LABEL,
  NAME_LABEL_FULL,
  NOTES,
  PHONE_LABEL,
  PHONE_LABEL_FULL,
  PHONE_NUMBER,
  PICKUP_ADDRESS,
  PROVIDE_CATNIP,
  PROVIDE_CATNIP_FULL,
  PROVIDE_TEMPTATIONS,
  PROVIDE_TEMPTATIONS_FULL,
  PROVIDE_TREATS,
  PROVIDE_TREATS_FULL,
  REQUEST_ERROR,
  REQUEST_SUCCESS,
  SELECTORS,
  SF_ADDRESSES_ONLY,
  SPECIFY_TIME,
  SUMMARY,
  TO_LABEL,
  VET_CONTACT_INFO,
  VET_LABEL,
  VET_LABEL_FULL,
  YOUR_CATS_NAME,
  YOUR_NAME
} from '../../constants';

export default () => {
  const today = new Date();
  const tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  const [startDate, setStartDate] = useState(today);
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState(tomorrow);
  const [endTime, setEndTime] = useState('');
  const [isDropOff, setIsDropOff] = useState(false);
  const [isPickUp, setIsPickUp] = useState(false);
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [catName, setCatName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [vetContact, setVetContact] = useState('');
  const [kittyQuirksText, setKittyQuirksText] = useState('');
  const [message, setMessage] = useState('');
  const [isCatnip, setIsCatnip] = useState(true);
  const [isTemptations, setIsTemptations] = useState(true);
  const [isFreezeDried, setIsFreezeDried] = useState(true);
  const [isMedication, setIsMedication] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isConfirmOverlay, setIsConfirmOverlay] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [overlayContent, setOverlayContent] = useState();

  useEffect(() => {
    if (!isValidating) return;

    const { name, email, phone, pickupAddress, dropOffAddress, validity } =
      SELECTORS;

    const isNameValid = document.querySelector(`${name}${validity}`);
    const isEmailValid = document.querySelector(`${email}${validity}`);
    const isPhoneValid = document.querySelector(`${phone}${validity}`);
    const isPickupAddressValid = document.querySelector(
      `${pickupAddress}${validity}`
    );
    const isDropOffAddressValid = document.querySelector(
      `${dropOffAddress}${validity}`
    );

    const invalids = [];

    let isDisabled = false;

    if (!isNameValid) {
      invalids.push(NAME);
      isDisabled = true;
    }

    if (!isEmailValid) {
      invalids.push(EMAIL_ADDRESS);
      isDisabled = true;
    }

    if (!isPhoneValid) {
      invalids.push(PHONE_NUMBER);
      isDisabled = true;
    }

    if (isPickUp && !isPickupAddressValid) {
      invalids.push(PICKUP_ADDRESS);
      isDisabled = true;
    }

    if (isDropOff && !isDropOffAddressValid) {
      invalids.push(DROPOFF_ADDRESS);
      isDisabled = true;
    }

    setDisabled(isDisabled);
    setInvalidFields(invalids);
  }, [isValidating, isDropOff, isPickUp, name, email, phone, address]);

  const onChangeStartTime = ({ target: { value } }) => setStartTime(value);
  const onChangeEndTime = ({ target: { value } }) => setEndTime(value);
  const onChangePickUp = checked => setIsPickUp(checked);
  const onChangeDropOff = checked => setIsDropOff(checked);
  const onChangeCatnip = checked => setIsCatnip(checked);
  const onChangeTemptations = checked => setIsTemptations(checked);
  const onChangeFreezeDried = checked => setIsFreezeDried(checked);
  const onChangeMedication = checked => setIsMedication(checked);

  const onChangeName = ({ target: { value } }) => setName(value);
  const onChangeCatName = ({ target: { value } }) => setCatName(value);
  const onChangeEmail = ({ target: { value } }) => setEmail(value);
  const onChangePhone = ({ target: { value } }) => setPhone(value);
  const onChangeAddress = ({ target: { value } }) => setAddress(value);
  const onChangeVetContact = ({ target: { value } }) => setVetContact(value);

  const onChangeQuirks = ({ target: { value } }) => setKittyQuirksText(value);
  const onChangeMessage = ({ target: { value } }) => setMessage(value);

  const onClickBook = () => {
    setIsConfirmOverlay(true);
    setIsValidating(true);
  };

  const onClickClose = () => setIsConfirmOverlay(false);

  const onClickSubmit = async () => {
    if (!disabled) {
      setDisabled(true);

      const { success } = await postJSON(BOOK_HOSTING_URL, payload);

      if (success) {
        setOverlayContent(REQUEST_SUCCESS);
        setDisabled(true);

        return;
      }

      setOverlayContent(REQUEST_ERROR);
    }
  };

  const payload = {
    name,
    catName,
    startDate: startDate.toString().substring(0, 15),
    endDate: endDate.toString().substring(0, 15),
    startTime,
    endTime,
    isDropOff,
    isPickUp,
    isCatnip,
    isTemptations,
    isFreezeDried,
    isMedication,
    kittyQuirksText,
    message,
    email,
    phone,
    address,
    vetContact
  };

  return (
    <>
      {isConfirmOverlay && (
        <aside id="confirm" className={overlayContent ? 'success' : ''}>
          <button id="back-button" onClick={onClickClose}>
            ⬅
          </button>
          {overlayContent || (
            <>
              <h4>{SUMMARY}</h4>
              {invalidFields?.length ? (
                <p className="error">
                  {INVALID_LABEL} {invalidFields.join(', ')}.
                </p>
              ) : (
                ''
              )}
              <div>
                <strong>{NAME_LABEL}</strong>&nbsp;
                <div>{name}</div>
              </div>
              <div>
                <strong>{FROM_LABEL}</strong>&nbsp;
                <div>
                  {startDate.toLocaleDateString()}{' '}
                  {startTime ? `at ${startTime}` : SPECIFY_TIME}
                </div>
              </div>
              <div>
                <strong>{KITTY_NEEDS_A_RIDE}</strong>&nbsp;
                <div>{toYesNo(isPickUp)}</div>
              </div>
              <div>
                <strong>{TO_LABEL}</strong>&nbsp;
                <div>
                  {endDate.toLocaleDateString()}{' '}
                  {endTime ? `at ${endTime}` : SPECIFY_TIME}
                </div>
              </div>
              <div>
                <strong>{KITTY_NEEDS_A_RIDE}</strong>&nbsp;
                <div>{toYesNo(isDropOff)}</div>
              </div>
              <div>
                <strong>{PROVIDE_CATNIP}</strong>&nbsp;
                <div>{toYesNo(isCatnip)}</div>
              </div>
              <div>
                <strong>{PROVIDE_TEMPTATIONS}</strong>&nbsp;
                <div>{toYesNo(isTemptations)}</div>
              </div>
              <div>
                <strong>{PROVIDE_TREATS}</strong>&nbsp;
                <div>{toYesNo(isFreezeDried)}</div>
              </div>
              <div>
                <strong>{KITTY_QUIRKS_LABEL}</strong>&nbsp;
                <div>{kittyQuirksText}</div>
              </div>
              <div>
                <strong>{ADDITIONAL_INFO_LABEL}</strong>&nbsp;
                <div>{message}</div>
              </div>
              <div>
                <strong>{EMAIL_LABEL_FULL}</strong>&nbsp;
                <div>{email}</div>
              </div>
              <div>
                <strong>{PHONE_LABEL_FULL}</strong>&nbsp;
                <div>{phone}</div>
              </div>
              <div>
                <strong>{ADDRESS_LABEL_FULL}</strong>&nbsp;
                <div>{address}</div>
              </div>
              <div>
                <strong>{VET_LABEL_FULL}</strong>&nbsp;
                <div>{vetContact}</div>
              </div>
              <button id="submit" onClick={onClickSubmit} disabled={disabled}>
                {CONFIRM}
              </button>
              <button id="close" onClick={onClickClose}>
                {EDIT}
              </button>
            </>
          )}
        </aside>
      )}
      <h1>{HOSTING}</h1>
      <div className="intro">
        {HOSTING_DISCLAIMER}
      </div>
      <p>
        <strong>{HOSTING_PRICE}</strong>
      </p>
      <NoVacancy />
      <div className="datepicker-group">
        <h5>{CHECK_IN_LABEL}</h5>
        <div className="datepicker-label-group">
          <div>{CALENDAR_EMOJI}</div>
          <DatePicker
            excludeDates={excludedDates}
            selected={startDate}
            onChange={date => setStartDate(date)}
          />
        </div>
        <div className="datepicker-label-group">
          <div>{CLOCK_EMOJI}</div>
          <input
            type="text"
            className="time-picker"
            value={startTime}
            onChange={onChangeStartTime}
            placeholder={CHECK_IN_TIME}
            pattern="[a-zA-Z0-9\s]{2,}"
            required={isValidating}
          />
        </div>
      </div>
      <div className="label-group">
        <label className="react-switch-label">
          <span style={{ paddingLeft: '1.6rem' }}>
            {KITTY_NEEDS_A_RIDE_FULL}
          </span>
          <Switch
            onChange={onChangePickUp}
            checked={isPickUp}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      {isPickUp && (
        <div className="contact panel">
          <h5>{ADDRESS_LABEL}</h5>
          <input
            id="pick-up-address"
            type="text"
            onChange={onChangeAddress}
            placeholder={SF_ADDRESSES_ONLY}
            value={address}
            pattern="[a-zA-Z0-9\s\W+]{2,}"
            required={isValidating}
          />
        </div>
      )}
      <div className="datepicker-group">
        <h5>{CHECK_OUT_LABEL}</h5>
        <div className="datepicker-label-group">
          <div>{CALENDAR_EMOJI}</div>
          <DatePicker
            excludeDates={excludedDates}
            required
            selected={endDate}
            onChange={date => setEndDate(date)}
          />
        </div>
        <div className="datepicker-label-group">
          <div>{CLOCK_EMOJI}</div>
          <input
            type="text"
            className="time-picker"
            value={endTime}
            onChange={onChangeEndTime}
            placeholder={CHECK_OUT_TIME}
            pattern="[a-zA-Z0-9\s]{2,}"
            required={isValidating}
          />
        </div>
      </div>
      <div className="label-group">
        <label className="react-switch-label">
          <span style={{ paddingLeft: '1.6rem' }}>
            {KITTY_NEEDS_A_RIDE_FULL}
          </span>
          <Switch
            onChange={onChangeDropOff}
            checked={isDropOff}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      {isDropOff && (
        <div className="contact panel">
          <h5>{ADDRESS_LABEL}</h5>
          <input
            id="drop-off-address"
            type="text"
            onChange={onChangeAddress}
            placeholder={SF_ADDRESSES_ONLY}
            value={address}
            pattern="[a-zA-Z0-9\s\W+]{2,}"
            required={isValidating}
          />
        </div>
      )}
      <h5>{KITTY_QUIRKS_LABEL}</h5>
      <textarea
        value={kittyQuirksText}
        onChange={onChangeQuirks}
        placeholder={CAT_DESCRIPTION}
      />
      <h5>{EXTRAS_LABEL}</h5>
      <div className="label-group">
        <label className="react-switch-label">
          <span>{PROVIDE_CATNIP_FULL}</span>
          <Switch
            onChange={onChangeCatnip}
            checked={isCatnip}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      <div className="label-group">
        <label className="react-switch-label">
          <span>{PROVIDE_TEMPTATIONS_FULL}</span>
          <Switch
            onChange={onChangeTemptations}
            checked={isTemptations}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      <div className="label-group">
        <label className="react-switch-label">
          <span>{PROVIDE_TREATS_FULL}</span>
          <Switch
            onChange={onChangeFreezeDried}
            checked={isFreezeDried}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      <div className="label-group">
        <label className="react-switch-label">
          <span>{ADMINISTER_MEDICATION}</span>
          <Switch
            onChange={onChangeMedication}
            checked={isMedication}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      <div className="contact">
        <h5>{NAME_LABEL_FULL}</h5>
        <input
          id="name"
          type="text"
          placeholder={YOUR_NAME}
          required={isValidating}
          value={name}
          onChange={onChangeName}
        />
      </div>
      <div className="contact">
        <h5>{CAT_NAME_LABEL_FULL}</h5>
        <input
          id="cat-name"
          type="text"
          placeholder={YOUR_CATS_NAME}
          value={catName}
          onChange={onChangeCatName}
        />
      </div>
      <div className="contact">
        <h5>{EMAIL_LABEL}</h5>
        <input
          id="email"
          type="email"
          placeholder={capitalize(EMAIL_ADDRESS)}
          required={isValidating}
          value={email}
          onChange={onChangeEmail}
        />
      </div>
      <div className="contact">
        <h5>{PHONE_LABEL}</h5>
        <input
          id="phone"
          type="tel"
          pattern="[a-zA-Z0-9\s\W+]{2,}"
          placeholder={capitalize(PHONE_NUMBER)}
          required={isValidating}
          value={phone}
          onChange={onChangePhone}
        />
      </div>
      <div className="contact">
        <h5>{VET_LABEL}</h5>
        <input
          type="text"
          placeholder={VET_CONTACT_INFO}
          value={vetContact}
          onChange={onChangeVetContact}
        />
      </div>
      <h5>{COMMENTS_LABEL}</h5>
      <textarea
        value={message}
        onChange={onChangeMessage}
        placeholder={NOTES}
      />
      <button id="book" onClick={onClickBook} disabled={disabled}>
        {BOOK_IT}
      </button>
    </>
  );
};
