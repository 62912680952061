import { CURRENCY, LANGUAGE_FULL, USD } from '../constants';

export default value => {
  const currency = new Intl.NumberFormat(LANGUAGE_FULL, {
    style: CURRENCY,
    currency: USD
  });

  return currency.format(value);
};
