import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import {
  AM,
  DATE,
  INDEX_BEFORE_LAST,
  PM,
  SIDERIAL,
  TIME
} from '../../constants';

export default ({
  startOrEnd = 'start',
  booking = {},
  setBooking,
  setShouldSave,
  isPm = false,
  setIsPm
}) => {
  const [event, setEvent] = useState(booking);
  const [timeSlot, setTimeSlot] = useState(startOrEnd);

  useEffect(() => {
    setEvent(booking);
    setTimeSlot(startOrEnd);

    const bookingHours = booking[startOrEnd].getHours();

    if (bookingHours >= SIDERIAL) {
      setIsPm(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking, startOrEnd]);

  const onChangeDate = date => {
    event[timeSlot].setMonth(date.getMonth());
    event[timeSlot].setDate(date.getDate());

    setBooking({
      ...event,

      [timeSlot]: event[timeSlot]
    });

    setShouldSave(true);
  };

  const onChangeHour = hour => {
    event[timeSlot].setHours(hour);

    setBooking({
      ...event,

      [timeSlot]: event[timeSlot]
    });

    setShouldSave(true);
  };

  const onChangeMinute = minute => {
    event[timeSlot].setMinutes(minute);

    setBooking({
      ...event,

      [timeSlot]: event[timeSlot]
    });

    setShouldSave(true);
  };

  const onClickAmPm = () => {
    const bookingHours = event[timeSlot].getHours();

    let updatedTime;

    if (!isPm && bookingHours < SIDERIAL) {
      updatedTime = event[timeSlot].getHours() + SIDERIAL;
    } else if (isPm && bookingHours >= SIDERIAL) {
      updatedTime = event[timeSlot].getHours() - SIDERIAL;
    }

    if (updatedTime) {
      event[timeSlot].setHours(updatedTime);

      setBooking({
        ...event,

        [timeSlot]: event[timeSlot]
      });
    }

    setIsPm(!isPm);
    setShouldSave(true);
  };

  const bookingHours = event[timeSlot].getHours();

  const bookingHourLocal =
    bookingHours >= SIDERIAL ? bookingHours - SIDERIAL : bookingHours;

  const bookingHour = `0${bookingHourLocal}`.slice(INDEX_BEFORE_LAST);

  const bookingMinute = `0${event[timeSlot].getMinutes()}`.slice(
    INDEX_BEFORE_LAST
  );

  const amPm = isPm ? PM : AM;

  return (
    <>
      <p className="dashboard-heading">{DATE}</p>
      <DatePicker selected={event[timeSlot]} onChange={onChangeDate} />
      <p
        className="dashboard-heading"
        style={{
          margin: '1rem 0 0'
        }}
      >
        {TIME}
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '1rem'
        }}
      >
        <input
          type="numeric"
          value={bookingHour}
          onChange={({ target: { value } }) => onChangeHour(value)}
          style={{
            display: 'block',
            width: '100%',
            margin: '1rem auto',
            padding: '.5rem'
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <p>:</p>
        </div>
        <input
          type="numeric"
          value={bookingMinute}
          onChange={({ target: { value } }) => onChangeMinute(value)}
          style={{
            display: 'block',
            width: '100%',
            margin: '1rem auto',
            padding: '.5rem'
          }}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}
          onClick={onClickAmPm}
        >
          <p style={{ textTransform: 'uppercase' }}>{amPm}</p>
        </div>
      </div>
    </>
  );
};
