const { REACT_APP_VISITS_URL } = process.env;

export default {
  'Can you meet my cat before I book a reservation?': `Yes! If you are located in San Francisco, I would be glad to come by for a meet and greet. Send me an email at <a href="mailto:chelsea@slowblinkscatcare.com">chelsea@slowblinkscatcare.com</a> to set up a time.`,
  'My cat can be aggressive, sometimes they hiss and swat. Would you still offer your services to us?':
    'Absolutely. I have a deep connection to cats and understand their behavior. I will give your cat the space they need while still honoring my commitment to their care, comfort and safety.',
  "My cat doesn't do well away from home or with other cats, are you available for in-home visits?": `Yes! Please click <a href="${REACT_APP_VISITS_URL}" target="_blank">this link</a> to book visits.`,
  'I am a new cat owner, do you recommend hosting or in-home visits?':
    "Cats are very sensitive to new environments and my ultimate goal with Slow Blinks is to care for and comfort your cat while you're away. Hosting can be tough for most cats due to the change of surroundings, so I really only recommend it for special circumstances.",
  'Can I know how my kitty is doing during my time away?':
    'Of course! I provide daily updates via email or text with photos to keep you in the know!',
  'For in-home visits, how do you dispose of litter box waste?':
    'I take all litter box waste and dispose of it outside of your home. No mess and no smell.',
  'Does my cat need up-to-date vaccines in order to use your services?':
    'Cats must be on flea prevention medication and have up-to-date vaccines to be eligible for hosting.',
  'What if my kitty has medical needs?':
    'No worries. Please leave the name of the medication and required dosage in the additional comments section when you book your reservation. Keep all medication in its original packaging.',
  'If you host my cat for me, what do I need to provide?':
    "Really just their regular food. Although I encourage owners to include their kitty's favorite treats and toys to make them feel as comfortable as possible. I will always have treats, toys, and a clean litter box for them by default! I also recommend a blanket, towel or even an old t-shirt that they can lay on or cuddle up with &ndash; anything that smells like home.",
  'Do you offer your services outside of San Francisco?':
    'For the most part, I am only taking clients that reside in San Francisco. If you live in the Bay Area and are in need of my services long-term, message me and we can make it work!',
  'When should I pay for services?':
    'I will send an invoice after my last visit or at the end of their last day of hosting.',
  'What forms of payment do you accept?':
    'I accept most forms of payment, including <a href="https://venmo.com/u/Chelsea-Lee-195" target="_blank">Venmo</a>.',
  'Is there a place where I can see or leave reviews for Slow Blinks?':
    'Check out Slow Blinks on <a href="https://www.yelp.com/biz/slow-blinks-cat-care-san-francisco" target="_blank">Yelp</a>!'
};
