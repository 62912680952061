import Questions from './Questions';

export default () => (
  <>
    {Object.keys(Questions).map(question => (
      <div key={question} className="question">
        <h5 style={{ fontWeight: 400 }}>Q: {question}</h5>
        <p>
          <strong
            dangerouslySetInnerHTML={{ __html: `A: ${Questions[question]}` }}
          />
        </p>
      </div>
    ))}
    <p>
      Have any other questions? Email me at{' '}
      <a href="mailto:chelsea@slowblinkscatcare.com">
        chelsea@slowblinkscatcare.com
      </a>
      .
    </p>
  </>
);
