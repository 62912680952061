import { useEffect, useState } from 'react';

import useTokenAuth from '../useTokenAuth';

import postJSON from '../../utils/postJSON';

import { CUSTOMER_NOT_FOUND, CUSTOMER_URL } from '../../constants';

const redirect = () => {
  window.location.href = '/calendar';
};

export default slug => {
  const { email, token } = useTokenAuth();

  const [customer, setCustomer] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (slug) {
      if (token) {
        fetchCustomer();
      }
    } else {
      redirect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchCustomer = async () => {
    const result = await postJSON(CUSTOMER_URL, {
      slug,
      token,
      username: email
    });

    setIsLoading(false);

    if (result?.customer?.id) {
      setCustomer(result.customer);

      return;
    }

    alert(CUSTOMER_NOT_FOUND);
    redirect();
  };

  return {
    customer,
    setCustomer,
    fetchCustomer,
    isLoading
  };
};
