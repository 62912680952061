import { PAGE_TITLE } from '../../constants';

export default ({ small = false }) => (
  <a href="/">
    <img
      id="logo"
      src="/slow-blinks.png"
      alt={PAGE_TITLE}
      width={small ? 300 : 400}
      height={small ? 105 : 157}
      style={small ? { opacity: .5 } : {}}
    />
  </a>
);
