import React, { useEffect, useState } from 'react';

import { END, START } from '../../constants';

export default ({
  startComponent,
  endComponent,
  booking = {},
  setIsPm,
  setIsSidebarStart
}) => {
  const [isStart, setIsStart] = useState(true);

  useEffect(() => {
    const hours = booking[isStart ? 'start' : 'end']?.getHours?.() << 0;

    setIsPm(hours >= 12);
    setIsSidebarStart(isStart);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStart]);

  return (
    <div style={{ marginTop: '2rem' }}>
      <ul
        style={{
          listStyle: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#9418ff',
          border: '2px solid #9418ff',
          borderRadius: '100vw',
          padding: 0
        }}
      >
        <li
          style={{
            background: isStart ? '#9418ff' : 'white',
            color: isStart ? 'white' : '#9418ff',
            padding: '.5rem',
            width: '100%',
            borderRadius: 'inherit',
            cursor: 'pointer'
          }}
          onClick={() => setIsStart(true)}
        >
          {START}
        </li>
        <li
          style={{
            background: !isStart ? '#9418ff' : 'white',
            color: !isStart ? 'white' : '#9418ff',
            padding: '.5rem',
            width: '100%',
            borderRadius: 'inherit',
            cursor: 'pointer'
          }}
          onClick={() => setIsStart(false)}
        >
          {END}
        </li>
      </ul>
      {isStart && startComponent}
      {!isStart && endComponent}
    </div>
  );
};
