import { useEffect, useState } from 'react';

import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ProfilePhoto, SidebarNav } from '../../components';
import { useCustomer, useTokenAuth } from '../../hooks';

import capitalize from '../../utils/capitalize';
import postJSON from '../../utils/postJSON';
import toDollars from '../../utils/toDollars';

import {
  ADDRESS,
  ARE_YOU_SURE,
  CONTACT,
  CREATE_INVOICE,
  DEFAULT_RATE,
  EDIT_PROFILE,
  ENTER_VALID_ADDRESS,
  ENTER_VALID_EMAIL,
  ENTER_VALID_PHONE,
  ENTER_WHOLE_NUMBER,
  INVOICES,
  IN_HOME_RATE_LABEL,
  NOTE,
  NO_INVOICES,
  RATE,
  SAVE,
  UPDATE_URL
} from '../../constants';

import '../../dashboard.css';

const { REACT_APP_API_URL } = process.env;

export default ({ slug }) => {
  const { email, token } = useTokenAuth();
  const { customer, setCustomer } = useCustomer(slug);

  const [customerNotes, setCustomerNotes] = useState(customer?.notes);
  const [customerEmail, setCustomerEmail] = useState(customer?.email);
  const [customerPhone, setCustomerPhone] = useState(customer?.phone);
  const [customerAddress, setCustomerAddress] = useState(customer?.address);
  const [customerRate, setCustomerRate] = useState(customer?.rate || DEFAULT_RATE);
  const [isSidebarNavOpen, setIsSidebarNavOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setCustomerNotes(customer?.notes);
    setCustomerEmail(customer?.email);
    setCustomerPhone(customer?.phone);
    setCustomerAddress(customer?.address);
    setCustomerRate(customer?.rate);
  }, [customer]);

  const onClickSave = async () => {
    const result = await postJSON(UPDATE_URL, {
      customerId: customer.id,
      phone: customerPhone,
      address: customerAddress,
      notes: customerNotes,
      rate: customerRate,
      token,
      username: email
    });

    setIsEditing(false);

    if (result?.success) {
      setCustomer({
        ...customer,

        notes: customerNotes,
        email: customerEmail,
        phone: customerPhone,
        address: customerAddress,
        rate: customerRate
      });
    }
  };

  const onChangeNotes = ({ target: { value } }) => setCustomerNotes(value);

  const onChangeEmail = ({ target: { value } }) => setCustomerEmail(value);

  const onChangeAddress = ({ target: { value } }) => setCustomerAddress(value);

  const onChangeRate = ({ target: { value } }) => setCustomerRate(value);

  const onChangePhone = ({ target: { value } }) => setCustomerPhone(value);

  const onClickInvoiceLink = invoiceId => () => {
    window.location.href = `/invoice/${customer.slug}/${invoiceId}`;
  };

  const onClickCreateInvoice = () => {
    window.location.href = `/invoice/${customer.slug}`;
  };

  const onClickDeleteInvoice = id => async () => {
    if (!window.confirm(ARE_YOU_SURE)) return;

    const response = await fetch(`${REACT_APP_API_URL}/delete-invoice`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id,
        customerId: customer.id,
        token,
        username: email
      })
    });

    if (response?.ok) {
      const result = await response.json();

      if (result?.success) {
        window.location.reload();
      }
    }
  };

  const onClickMenu = () => {
    setIsSidebarNavOpen(!isSidebarNavOpen);
  };

  const onClickEdit = () => setIsEditing(!isEditing);

  if (!customer?.id) {
    return <div />;
  }

  const {
    id,
    ownerName,
    catName,
    address,
    phone,
    email: emailAddress,
    notes,
    invoices = [],
    rate = DEFAULT_RATE
  } = customer;

  const customerEmailAddress = emailAddress || id;

  return (
    <>
      <section className="sidebar-section">
        <SidebarNav isOpen={isSidebarNavOpen} setIsOpen={setIsSidebarNavOpen} />
        <div className="customers-wrapper">
          <h2>
            <i onClick={onClickMenu}>=</i>@{slug}
          </h2>
          <header className="profile-header">
            <ProfilePhoto />
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ fontSize: '1.2em' }}>{catName}</h3>
              <h3>{ownerName}</h3>
              {!isEditing && (
                <div className="panel">
                  <section>
                    {customerEmailAddress && <p>{customerEmailAddress}</p>}
                    {!customerEmailAddress && (
                      <p className="panel">⚠️ No email address</p>
                    )}
                    {phone && <p className="phone">{phone}</p>}
                    {!phone && <p className="panel">⚠️ No phone number</p>}
                  </section>
                  <section>{address}</section>
                  <section>{notes || '-'}</section>
                  <section className="disclaimer">
                    {IN_HOME_RATE_LABEL} {toDollars(rate)}
                  </section>
                </div>
              )}
            </div>
          </header>
          {isEditing && (
            <>
              <p className="dashboard-heading">{CONTACT}</p>
              <input
                type="email"
                placeholder={ENTER_VALID_EMAIL}
                defaultValue={id}
                onChange={onChangeEmail}
                value={customerEmail}
                disabled
              />
              <input
                type="tel"
                placeholder={ENTER_VALID_PHONE}
                defaultValue={phone}
                onChange={onChangePhone}
                value={customerPhone}
              />
              <p className="dashboard-heading">{capitalize(ADDRESS)}</p>
              <input
                type="address"
                placeholder={ENTER_VALID_ADDRESS}
                defaultValue={address}
                onChange={onChangeAddress}
                value={customerAddress}
              />
              <p className="dashboard-heading">{NOTE}</p>
              <textarea defaultValue={customerNotes} onChange={onChangeNotes} />
              <p className="dashboard-heading">{RATE}</p>
              <input
                type="number"
                placeholder={ENTER_WHOLE_NUMBER}
                defaultValue={rate}
                onChange={onChangeRate}
                value={customerRate}
              />
              <button onClick={onClickSave}>{SAVE}</button>
            </>
          )}
          {!isEditing && <button onClick={onClickEdit}>{EDIT_PROFILE}</button>}
          <div className="panel file-list">
            <p className="dashboard-heading" style={{ margin: 0 }}>
              {INVOICES}
            </p>
            <ul className="profile-invoices">
              {invoices.map(({ id, createdAt }) => (
                <li key={id}>
                  <FontAwesomeIcon icon={faFilePdf} color="#9418ff" />
                  <button
                    onClick={onClickInvoiceLink(id)}
                    style={{
                      background: 'none',
                      margin: 0,
                      padding: 0,
                      color: '#9418ff',
                      textDecoration: 'underline'
                    }}
                  >
                    {createdAt}
                  </button>
                  <button
                    onClick={onClickDeleteInvoice(id)}
                    className="delete-button"
                  >
                    ×
                  </button>
                </li>
              ))}
            </ul>
            {invoices.length === 0 ? (
              <p style={{ opacity: 0.5 }}>{NO_INVOICES}</p>
            ) : (
              ''
            )}
            <button onClick={onClickCreateInvoice}>{CREATE_INVOICE}</button>
          </div>
        </div>
      </section>
    </>
  );
};
