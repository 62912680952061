import { useEffect, useState } from 'react';

import { useTokenAuth } from '../../hooks';

import postJSON from '../../utils/postJSON';

import { LOGIN, LOGIN_URL } from '../../constants';

import '../../dashboard.css';

const redirect = () => {
  window.location.href = '/calendar';
};

export default () => {
  const { email, token, createSession } = useTokenAuth();

  const [username, setUsername] = useState(email);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (token) {
      redirect();
    }
  }, [token]);

  const onClickLogin = async () => {
    if (!username || !password) return;

    const result = await postJSON(LOGIN_URL, {
      username,
      password
    });

    if (result?.token) {
      createSession({
        email: result.email,
        token: result.token
      });

      redirect();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <input
        type="email"
        value={username}
        onChange={({ target: { value } }) => setUsername(value)}
        style={{
          padding: '.5rem',
          width: '100%',
          display: 'block'
        }}
      />
      <input
        type="password"
        value={password}
        onChange={({ target: { value } }) => setPassword(value)}
        style={{
          padding: '.5rem',
          width: '100%',
          display: 'block'
        }}
      />
      <button onClick={onClickLogin}>{LOGIN}</button>
    </div>
  );
};
