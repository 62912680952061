import { useEffect } from 'react';

import postJSON from '../../utils/postJSON';

import { LOGIN_URL, USER } from '../../constants';

const redirect = () => {
  window.location.href = '/login';
};

export default () => {
  const [pageName] = window.location.pathname
    .trim()
    .toLowerCase()
    .split('/')
    .filter(Boolean);

  const { email = '', token = '' } = JSON.parse(
    localStorage.getItem(USER) || '{}'
  );

  useEffect(() => {
    if (pageName !== 'login' && !token) {
      return redirect();
    }

    const fetchToken = async () => {
      const { error } = await postJSON(LOGIN_URL, {
        username: email,
        token
      });

      if (error) {
        deleteSession();
      }
    };

    if (token) {
      fetchToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, email, token]);

  const createSession = ({ email, token }) =>
    localStorage.setItem(
      USER,
      JSON.stringify({
        email,
        token,
        createdAt: Date.now()
      })
    );

  const deleteSession = () => {
    localStorage.clear();
    redirect();
  };

  return {
    email,
    token,
    createSession,
    deleteSession
  };
};
