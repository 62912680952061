import { EventDateTime, ProfilePhoto, SegmentedControl } from '..';
import { useEvents, useTokenAuth } from '../../hooks';

import postJSON from '../../utils/postJSON';

import {
  ACTIONS,
  ARE_YOU_SURE,
  CHANGE_URL,
  CLOSE,
  DELETE,
  REMOVE_URL
} from '../../constants';

export default ({
  isPm = false,
  booking = {},
  setIsPm,
  setBooking,
  shouldSave,
  setShouldSave,
  setIsSidebarStart
}) => {
  const { email, token } = useTokenAuth();
  const { fetchEvents } = useEvents();

  const onClickDelete = async () => {
    if (!window.confirm(ARE_YOU_SURE)) return;

    const result = await postJSON(REMOVE_URL, {
      id: booking.id,
      token,
      username: email
    });

    if (result?.success) {
      fetchEvents();
    }

    setShouldSave(false);
    setBooking({});
  };

  const onClickViewProfile = () => {
    const slug = booking.catName
      ? booking.catName
          .trim()
          .replace(/,/g, '')
          .replace(/ /g, '-')
          .replace(/&/g, 'and')
          .toLowerCase()
      : '404';

    window.location.href = `/profile/${slug}`;
  };

  const onClickClose = async () => {
    if (shouldSave) {
      const result = await postJSON(CHANGE_URL, {
        bookingId: booking.id,
        start: booking.start,
        end: booking.end,
        token,
        username: email
      });

      if (result?.success) {
        fetchEvents();
      }

      setShouldSave(false);
    }

    setBooking({});
  };

  return (
    <div className={booking.id ? 'sidebar-open' : 'sidebar'}>
      <div onClick={onClickViewProfile} style={{ cursor: 'pointer' }}>
        <ProfilePhoto />
        <h3 style={{ fontSize: '1.2em' }}>{booking.title}</h3>
      </div>
      {booking.id && (
        <>
          <SegmentedControl
            startComponent={
              <EventDateTime
                startOrEnd="start"
                booking={booking}
                setBooking={setBooking}
                setShouldSave={setShouldSave}
                isPm={isPm}
                setIsPm={setIsPm}
              />
            }
            endComponent={
              <EventDateTime
                startOrEnd="end"
                booking={booking}
                setBooking={setBooking}
                setShouldSave={setShouldSave}
                isPm={isPm}
                setIsPm={setIsPm}
              />
            }
            booking={booking}
            setIsPm={setIsPm}
            setIsSidebarStart={setIsSidebarStart}
          />
          <p className="dashboard-heading">{ACTIONS}</p>
          <button
            onClick={onClickDelete}
            style={{
              background: 'transparent',
              border: '2px solid #ff1859',
              color: '#ff1859',
              width: '100%',
              margin: '1rem 0',
              padding: '1rem 0'
            }}
          >
            {DELETE}
          </button>
        </>
      )}
      <button
        onClick={onClickClose}
        style={{
          position: 'absolute',
          bottom: 0,
          left: '1rem',
          right: '1rem',
          top: 'auto',
          width: 'calc(100% - 2rem)'
        }}
      >
        {shouldSave ? 'Save & ' : ''}
        {CLOSE}
      </button>
    </div>
  );
};
