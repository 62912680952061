import { SLOW_BLINKS_EMAIL } from '../../constants';

const IMG_WIDTH = 966;
const IMG_HEIGHT = 1049;

export default () => <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem'
  }}
>
  <img
    src="/404.png"
    alt="Not found"
    width={`${IMG_WIDTH}px`}
    height={`${IMG_HEIGHT}px`}
    style={{
      display: 'block',
      width: `${IMG_WIDTH / 5}px`,
      height: `${IMG_HEIGHT / 5}px`
    }}
  />
  <h3 style={{ margin: '2rem .5rem 3rem', textAlign: 'center' }}>
    Oops! Our servers must be down.
    <br />
    For bookings or questions, please <a href={`mailto:${SLOW_BLINKS_EMAIL}`}>email Chelsea</a> directly :)
  </h3>
</div>;
