import React, { useEffect, useState } from 'react';

import { ProfilePhoto, SidebarNav } from '../../components';
import { useCustomers, useTokenAuth } from '../../hooks';

import capitalize from '../../utils/capitalize';
import postJSON from '../../utils/postJSON';

import {
  ACTIONS,
  ADDRESS,
  ADD_CUSTOMER,
  CAT,
  CATS_NAME,
  CREATE_URL,
  CUSTOMER,
  CUSTOMERS,
  CUSTOMER_CREATED,
  EMAIL_ADDRESS,
  ENTER_CATS_NAME,
  ENTER_OWNERS_NAME,
  ENTER_VALID_ADDRESS,
  ENTER_VALID_EMAIL,
  NAME,
  OWNERS_NAME,
  SAVE,
  VIEW
} from '../../constants';

export default () => {
  const { email, token } = useTokenAuth();
  const { customers, isLoading } = useCustomers();

  const [isSidebarNavOpen, setIsSidebarNavOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [customerName, setCustomerName] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerCatName, setCustomerCatName] = useState();
  const [customerAddress, setCustomerAddress] = useState();

  useEffect(() => {
    if (!token) {
      window.location.href = '/login';

      return;
    }

    document.body.onkeydown = ({ keyCode }) => {
      if (keyCode === 27) {
        setIsCreating(false);
      }
    };

    return () => (document.body.onkeydown = null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCreate = () => setIsCreating(true);

  const onChangeName = ({ target: { value } }) => setCustomerName(value);

  const onChangeCatName = ({ target: { value } }) => setCustomerCatName(value);

  const onChangeEmail = ({ target: { value } }) => setCustomerEmail(value);

  const onChangeAddress = ({ target: { value } }) => setCustomerAddress(value);

  const onClickSave = async () => {
    const result = await postJSON(CREATE_URL, {
      token,
      username: email,
      customerId: customerEmail,
      ownerName: customerName,
      catName: customerCatName,
      address: customerAddress
    });

    if (result?.success) {
      alert(CUSTOMER_CREATED);
      window.location.reload();
    }
  };

  const onClickMenu = () => {
    setIsSidebarNavOpen(!isSidebarNavOpen);
  };

  const onClickModal = ({ target: { className } }) => {
    if (className !== 'modal') return;

    setIsCreating(false);
  };

  return isLoading ? (
    <div />
  ) : (
    <>
      {isCreating && (
        <div className="modal" onClick={onClickModal}>
          <aside className="overlay">
            <div className="form">
              <h2>{ADD_CUSTOMER}</h2>
              <p className="dashboard-heading">{OWNERS_NAME}</p>
              <input
                type="text"
                placeholder={ENTER_OWNERS_NAME}
                onChange={onChangeName}
                value={customerName}
              />
              <p className="dashboard-heading">{CATS_NAME}</p>
              <input
                type="text"
                placeholder={ENTER_CATS_NAME}
                onChange={onChangeCatName}
                value={customerCatName}
              />
              <p className="dashboard-heading">{capitalize(EMAIL_ADDRESS)}</p>
              <input
                type="email"
                placeholder={ENTER_VALID_EMAIL}
                onChange={onChangeEmail}
                value={customerEmail}
              />
              <p className="dashboard-heading">{capitalize(ADDRESS)}</p>
              <input
                type="address"
                placeholder={ENTER_VALID_ADDRESS}
                onChange={onChangeAddress}
                value={customerAddress}
              />
              <button onClick={onClickSave}>{SAVE}</button>
            </div>
          </aside>
        </div>
      )}
      <section className="sidebar-section">
        <SidebarNav isOpen={isSidebarNavOpen} setIsOpen={setIsSidebarNavOpen} />
        <div className="customers-wrapper">
          <h2>
            <i onClick={onClickMenu}>=</i>
            {CUSTOMERS}
          </h2>
          <div>
            <button className="primary-action-button" onClick={onClickCreate}>
              + {CUSTOMER}
            </button>
          </div>
          <ul className="table">
            <li>
              <span className="dashboard-heading">🐱</span>
              <span className="dashboard-heading">{capitalize(NAME)}</span>
              <span className="dashboard-heading">{capitalize(CAT)}</span>
              <span />
              <strong className="dashboard-heading">{ACTIONS}</strong>
            </li>
            {customers &&
              customers.map(customer => (
                <li key={customer.id}>
                  <span>
                    <a href={`/profile/${customer.slug}`}>
                      <ProfilePhoto
                        style={{
                          width: '25px',
                          height: '25px',
                          margin: '0'
                        }}
                      />
                    </a>
                  </span>
                  <span>
                    <a href={`/profile/${customer.slug}`}>
                      {customer.ownerName}
                    </a>
                  </span>
                  <span>
                    <a href={`/profile/${customer.slug}`}>{customer.catName}</a>
                  </span>
                  <span />
                  <a className="button-link" href={`/profile/${customer.slug}`}>
                    {VIEW}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      </section>
    </>
  );
};
