import { usePDF } from 'react-to-pdf';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';

import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Invoice } from '../../components';
import { useCustomer } from '../../hooks';

import {
  INVOICE,
  PAGE_TITLE,
  PAY_WITH_VENMO_LABEL,
  VENMO_USERNAME
} from '../../constants';

import '../../dashboard.css';

const today = new Date();
const tomorrow = new Date(today);

tomorrow.setDate(tomorrow.getDate() + 1);

export default ({ slug, id }) => {
  const { customer } = useCustomer(slug);

  const { targetRef } = usePDF({
    filename: `${customer?.catName} - ${INVOICE}`
  });

  if (!customer?.id) {
    return <div />;
  }

  const invoice = customer.invoices.find(invoice => invoice.id === id);

  if (!invoice?.id) return <div />;

  const {
    parentType,
    bookingType,
    dueDate,
    invoiceLines,
    invoiceNumber,
    comment,
    adjustments,
    paid
  } = invoice;

  const onClickDownload = () => {
    generatePDF(targetRef, {
      filename: `${customer.catName} - ${INVOICE} ${invoiceNumber}`,
      resolution: Resolution.HIGH,
      margin: Margin.LARGE,
      page: {
        format: 'A4',
        orientation: 'portrait'
      },
      overrides: {
        pdf: {
          compress: false
        }
      }
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        ref={targetRef}
        style={{
          position: 'relative',
          overflow: 'hidden',
          width: 816,
          height: 1154
        }}
      >
        <div style={{ paddingTop: '.5rem' }}>
          <div
            style={{
              margin: '1rem 1rem 2rem',
              background: '#9418ff20',
              color: '#9418ff',
              padding: '1rem',
              borderRadius: '1rem',
              textAlign: 'center',
              fontSize: '.9em'
            }}
          >
            <strong>{PAY_WITH_VENMO_LABEL}</strong>&nbsp;{VENMO_USERNAME}
          </div>
          <Invoice
            customer={customer}
            parentType={parentType}
            bookingType={bookingType}
            dueDate={new Date(dueDate)}
            invoiceNumber={invoiceNumber}
            invoiceLines={invoiceLines}
            comment={comment}
            adjustments={adjustments}
            paid={paid}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'flex-end',
            background: '#9418ff20',
            padding: '1.4rem',
            bottom: 0,
            width: '100%'
          }}
        >
          <img
            src="/slow-blinks.png"
            alt={PAGE_TITLE}
            width={140}
            height={55}
            style={{ display: 'block' }}
          />
        </div>
      </div>
      <button
        onClick={onClickDownload}
        style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          width: '4rem',
          height: '4rem',
          padding: 0
        }}
      >
        <FontAwesomeIcon icon={faDownload} color="white" />
      </button>
    </div>
  );
};
