export default async (url, payload) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });

  if (response?.ok) {
    const result = await response.json();

    return {
      success: true,
      error: false,

      ...result
    };
  }

  return {
    success: false,
    error: true
  };
};
